import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'
import Img from "../components/image-transform"

const FreeTrial = ({content}) => {
  return (
    <div className="free-trial">
      <div className="container">
        <div className="content-wrapper">
          <div className="text-holder">
            <h2 className="title">{content.title}</h2> 
            <p className="description">{content.description}</p> 
          </div>

          <div className="image-holder">
            {
              content.svg ?
              <img src={content.image}  className="image" alt={content.imageName}/>
              :
              <Img filename={content.image} className="image" alt={content.imageName}/>
            }
          </div>


          <div className="button-holder">
            {content.buttons.map((item, i)=> {
              return (
                item.scheduleCustomizeButton ?
                <ScheduleADemoButton key={i} bigButton="true" buttonText={item.buttonText} buttonColor={item.buttonColor} buttonLarge={item.buttonLarge}/>
                :
                item.scheduleButton ?
                <ScheduleADemoButton key={i} buttonColor={item.buttonColor}/>
                :
                item.isExternal 
                ? 
                  <a href={item.url} key={i} target="_blank" rel="noopener noreferrer" className={classNames('btn', 
                    {"btn-orange" : item.buttonColor === "orange"},
                    {"btn-black" : item.buttonColor === "black"},
                    {"btn-orange-lg" : item.buttonLarge === true}
                  )}> {item.buttonText}</a>
                :
                <Link to={item.url} key={i} className={classNames('btn', 
                  {"btn-orange" : item.buttonColor === "orange"},
                  {"btn-black" : item.buttonColor === "black"},
                  {"btn-orange-lg" : item.buttonLarge === true}
                )}> {item.buttonText}</Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FreeTrial