import React from 'react'
// import { Link } from 'gatsby'
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const CaseStudyBanner = ({data}) => {
  return (
    <div className="case-study-banner">
      <div className="section-hero">
        <div className="container case-study-wrapper">
          <div className="description-column">
            { data.headline !==  '' && <h1 className="section-heading">{data.headline}</h1> }
            { data.subheading !== '' &&  <p className="section-text">{data.subheading}</p> }

            <div className="btn-wrapper">
              <a href="https://app.billsby.com/registration" target='_blank' rel="noopener noreferrer" className="btn-orange">Sign up today</a>
              <ScheduleADemoButton buttonColor="black"/>
            </div>
          </div>
          <div className="company-column">
            { data.companyLogo !== '' && 
              <a 
                href={data.companyUrl} 
                target="_blank" rel="noopener noreferrer"> 
                  <img 
                    src={data.companyLogo} 
                    alt="company-logo"
                    className="company-logo"  /> 
              </a> 
            }
            { data.companyDescription !== '' && <p className="section-text">{data.companyDescription}</p> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudyBanner
